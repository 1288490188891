import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReceiptList() {
  // Use toast
  const toast = useToast()

  const refReceiptListTable = ref(null)

  const perPage = ref(5)
  const totalReceipts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchFilter = ref(null)
  const employeeFilter = ref(null)
  const reasonFilter = ref(null)
  const itemFilter = ref(null)
  const dateFilterValue = ref(null)

  const current = new Date()
  const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
  const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
  const today = `${current.getFullYear()}-${month}-${date}`
  dateFilterValue.value = today

  const dataMeta = computed(() => {
    const localItemsCount = refReceiptListTable.value ? refReceiptListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReceipts.value,
    }
  })

  const refetchData = () => {
    refReceiptListTable.value.refresh()
  }

  watch([currentPage, perPage, branchFilter, employeeFilter, reasonFilter, itemFilter, dateFilterValue], () => {
    refetchData()
  })

  const reasonOptions = ref(JSON.parse(JSON.stringify([])))
  store
    .dispatch('common/fetchReasons', { id_name: 1, tag: 'Receipt' })
    .then(response => {
      const { reasons } = response.data.data
      for (let i = 0; i < reasons.length; i += 1) {
        reasonOptions.value.push({ label: reasons[i].name, value: reasons[i].id.toString(), branch: reasons[i].branch })
      }
      localStorage.reasons = JSON.stringify(reasonOptions.value)
    })
    .catch(error => {
      console.log(error)
    })

  const itemOptions = ref(JSON.parse(JSON.stringify([])))
  store
    .dispatch('common/fetchItems', { id_name: 1 })
    .then(response => {
      const { items } = response.data.data
      for (let i = 0; i < items.length; i += 1) {
        itemOptions.value.push({ label: items[i].name, value: items[i].id.toString() })
      }
    })
    .catch(error => {
      console.log(error)
    })

  const fetchReceipts = (ctx, callback) => {
    store
      .dispatch('receipt/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        branch_id: branchFilter.value,
        employee_id: employeeFilter.value,
        reason: reasonFilter.value,
        item_id: itemFilter.value,
        date_range: dateFilterValue.value,
      })
      .then(response => {
        const { receipts, total } = response.data.data

        callback(receipts)
        totalReceipts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting receipt list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchReceipts,
    perPage,
    currentPage,
    totalReceipts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReceiptListTable,

    refetchData,

    // Filters
    branchFilter,
    employeeFilter,
    reasonFilter,
    itemFilter,
    dateFilterValue,

    reasonOptions,
    itemOptions,
  }
}
